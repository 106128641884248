.App canvas {
    width: 100%;
    height: 100%;
}

.App {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

